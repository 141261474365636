import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { logout, loginWithQuery } from "./authSlice"; // Import loginWithQuery


// Async thunk for updating tenant details
export const updateTenant = createAsyncThunk(
  "profile/updateTenant",
  async ({ payload, tenantId, facilityId }, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await instance.put(
        `/facilities/${facilityId}/updateTenant`,
        payload,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      // After a successful update, refresh the profile by dispatching the login action.
      const { s, i } = getState().auth; // s: store id, i: tenant id
      dispatch(loginWithQuery(s, i));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profile: null, // User profile details
    loading: false,
    error: null,
    successMessage: null, // For success notifications
  },
  reducers: {
    clearProfileMessages: (state) => {
      state.error = null;
      state.successMessage = null;
    },
    updateProfileDetails: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTenant.fulfilled, (state, action) => {
        state.loading = false;
        // Merge the updated fields into the profile
        state.profile = { ...state.profile, ...action.payload };
        state.successMessage = "Tenant updated successfully";
      })
      .addCase(updateTenant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update tenant";
      })
      .addCase(logout, () => ({
        profile: null,
        alternateContacts: [],
        loading: false,
        error: null,
        successMessage: null,
      }));
  },
});

export const { clearProfileMessages, updateProfileDetails } = profileSlice.actions;
export default profileSlice.reducer;
