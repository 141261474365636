import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import {
  faUser,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faCity,
  faMapPin,
  faFlag,
  faMoneyBill,
  faBriefcase,
  faClock,
  faCalendarAlt,
  faCheck,
  faCreditCard,
  faFileContract,
  faMapMarkerAlt as faMoveIn,
  faTimes,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "./Spinner";
import { updateTenant } from "../redux/profileSlice"; // Adjust the path as needed

// Styled Components (unchanged)
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  text-align: center;
  color: #333;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const AnimatedFormSection = styled.div`
  margin-bottom: 20px;
  animation: ${(props) =>
    props.visible
      ? css`
          ${fadeIn} 0.3s ease-in-out
        `
      : css`
          ${fadeOut} 0.3s ease-in-out
        `};
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -30%;
    transform: translateY(-30%);
    height: 2px;
    width: 100%;
    background-color: #e22c2a;
    z-index: 0;
  }
`;

const StepCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#e22c2a" : "#f5f5f5")};
  color: ${(props) => (props.completed ? "#fff" : "#e22c2a")};
  border: 2px solid #e22c2a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StepLabel = styled.p`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 95%;
  font-size: 14px;
  min-height: 80px;
`;

const Note = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: center;
`;

const ConfirmButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;

  &:hover {
    background-color: #c92121;
  }
`;

const ToggleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`;
const ToggleSubSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
`;

const ToggleLabel = styled.label`
  font-size: 14px;
  color: #333;
  font-weight: bold;
  max-width: 80%;
`;

const Toggle = styled.input.attrs({ type: "checkbox" })`
  position: relative;
  width: 40px;
  height: 20px;
  appearance: none;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;

  &:checked {
    background-color: #e22c2a;
  }

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  &:checked::before {
    transform: translateX(20px);
  }
`;

const ToggleText = styled.span`
  margin-top: 5px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
`;


function ExtendedContactModal({ onRequestClose, onNext }) {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.auth.profile);
  const storeId = useSelector((state) => state.auth.s)
  const activeModalSession = useSelector((state) => state.modal.activeModalSession);
  const rentalsLoading = useSelector((state) => state.rentals.rentLoading);

  // Toggle states
  const [isVehicleStored, setIsVehicleStored] = useState(false);
  const [isLean, setIsLean] = useState(false);
  const [isMilitary, setIsMilitary] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);

  // Input state for Vehicle Section
  const [vehicleDetails, setVehicleDetails] = useState("");

  // Input states for Lien Section
  const [lienHolderName, setLienHolderName] = useState("");
  const [lienValue, setLienValue] = useState("");
  const [lienItems, setLienItems] = useState("");

  // Input states for Military Section
  const [militaryBranch, setMilitaryBranch] = useState("");
  const [militaryBase, setMilitaryBase] = useState("");
  const [coFirstName, setCoFirstName] = useState("");
  const [coLastName, setCoLastName] = useState("");
  const [coPhone, setCoPhone] = useState("");

  // Input states for Business Section
  const [businessName, setBusinessName] = useState("");
  const [storagePeriod, setStoragePeriod] = useState("");
  

  // Global loading state
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    if (cleaned.length < 4) return cleaned;
    if (cleaned.length < 7) return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  };
  const handlePhoneNumberChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setCoPhone(formattedNumber);
  };
  const handleLienValueChange = (e) => {
    let input = e.target.value;
    // Remove any character that is not a digit or period
    input = input.replace(/[^0-9.]/g, "");

    // Allow only one decimal point
    const parts = input.split(".");
    if (parts.length > 2) {
      input = parts[0] + "." + parts.slice(1).join("");
    }

    // If input is empty, update state to empty string
    if (input === "") {
      setLienValue("");
      return;
    }

    // Parse the number (this will ignore a trailing decimal point)
    const num = parseFloat(input);

    // Check if the user is still typing a decimal point (e.g. "123.")
    const hasTrailingDot = input.endsWith(".");

    if (hasTrailingDot) {
      // Display the raw input prefixed with "$" so the user can continue typing
      setLienValue("$" + input);
    } else {
      // Determine how many decimals were entered
      const decimalPart = input.split(".")[1];
      const decimalCount = decimalPart ? decimalPart.length : 0;
      // Format as USD currency while preserving the entered decimal count
      const formatted = num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimalCount,
        maximumFractionDigits: 2,
      });
      setLienValue(formatted);
    }
  };

  useEffect(() => {
    document.title = "Additional Info Modal";
  }, []);

  // Close modal when clicking outside of it
  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };

  const validateForm = () => {
    const validationErrors = {};

    // Validate Vehicle Details if toggle is on
    if (isVehicleStored && !vehicleDetails.trim()) {
      validationErrors.vehicleDetails = "Vehicle details are required.";
    }

    // Validate Lien Details if toggle is on
    if (isLean) {
      if (!lienHolderName.trim()) {
        validationErrors.lienHolderName = "Lien holder name is required.";
      }
      if (!lienValue.trim()) {
        validationErrors.lienValue = "Lien value is required.";
      } else if (isNaN(Number(lienValue.replace(/[^0-9.]/g, "")))) {
        validationErrors.lienValue = "Lien value must be a number.";
      }
      if (!lienItems.trim()) {
        validationErrors.lienItems = "List of lien items is required.";
      }
    }

    // Validate Military Details if toggle is on
    if (isMilitary) {
      if (!militaryBranch.trim()) {
        validationErrors.militaryBranch = "Military branch is required.";
      }
      if (!militaryBase.trim()) {
        validationErrors.militaryBase = "Military base is required.";
      }
      if (!coFirstName.trim()) {
        validationErrors.coFirstName = "Commanding officer first name is required.";
      }
      if (!coLastName.trim()) {
        validationErrors.coLastName = "Commanding officer last name is required.";
      }
      if (!coPhone.trim()) {
        validationErrors.coPhone = "Commanding officer's phone number is required.";
      }
    }

    // Validate Business Details if toggle is on
    if (isBusiness) {
      if (!businessName.trim()) {
        validationErrors.businessName = "Business name is required.";
      }
      if (!storagePeriod.trim()) {
        validationErrors.storagePeriod = "Storage period is required.";
      }
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!isVehicleStored && !isLean && !isMilitary && !isBusiness) {
      setLoading(false);
      onNext(activeModalSession ? 4 : 5);
      return;
    }
    let tenantId = tenant?.i || null;
    let facilityId = storeId || null;
    if(!tenantId) return;
    if (!validateForm()){ 
      return}; // Prevent submission if validation fails
    setLoading(true);

    // Build payload based on state values and toggles
    const payload = {
      // Vehicle Details
      vehicle_details: isVehicleStored ? vehicleDetails : null,

      // Lien Details
      lien_status: isLean,
      lien_holder_name: isLean ? lienHolderName : null,
      lien_value: isLean && lienValue
        ? Number(lienValue.replace(/[^0-9.]/g, ""))
        : null,
      lien_items: isLean ? lienItems : null,

      // Military Details
      military: isMilitary ? "true" : "false",
      military_branch: isMilitary ? militaryBranch : null,
      military_base: isMilitary ? militaryBase : null,
      military_co_first_name: isMilitary ? coFirstName : null,
      military_co_last_name: isMilitary ? coLastName : null,
      military_co_phone: isMilitary ? coPhone : null,

      // Business Details
      business: isBusiness ? "true" : "false",
      business_name: isBusiness ? businessName : null,
      storage_period: isBusiness ? storagePeriod : null,
      changeUser: tenant?.profile?.first_name+" "+tenant?.profile?.last_name || "unknown user"

    };
    
    
    dispatch(updateTenant({payload, tenantId, facilityId}))
      .unwrap()
      .then((data) => {
        setLoading(false);
        onNext(activeModalSession ? 4 : 5);
      })
      .catch((error) => {
        console.error("Error updating tenant:", error);
        setLoading(false);
        // Optionally show an error message to the user
        onNext(activeModalSession ? 4 : 5);
      });
  };

  return (
    <ModalWrapper onClick={handleWrapperClick}>
      <ModalContainer>
        <Header>Additional Details</Header>
        {loading || rentalsLoading ? (
          <Spinner />
        ) : (
          <>
            {/* Steps Container */}
            <StepsContainer>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faCreditCard} />
                </StepCircle>
                <StepLabel>Enter Payment</StepLabel>
              </Step>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faUser} />
                </StepCircle>
                <StepLabel>Alt Contact</StepLabel>
              </Step>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faClipboard} />
                </StepCircle>
                <StepLabel>More Info</StepLabel>
              </Step>
              <Step>
                <StepCircle>
                  <FontAwesomeIcon icon={faFileContract} />
                </StepCircle>
                <StepLabel>Sign Lease</StepLabel>
              </Step>
            </StepsContainer>

            {/* Vehicle Storage Section */}
            <ToggleSection>
              <ToggleLabel>
                Are you storing a vehicle (car, boat, RV, etc.)?
              </ToggleLabel>
              <ToggleSubSection>
                <Toggle
                  checked={isVehicleStored}
                  onChange={() => setIsVehicleStored(!isVehicleStored)}
                />
                <ToggleText>{isVehicleStored ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isVehicleStored}>
              <Label>Vehicle Details *</Label>
              <Textarea
                placeholder="Enter details about your vehicle(s) here."
                value={vehicleDetails}
                onChange={(e) => setVehicleDetails(e.target.value)}
              />
              {errors.vehicleDetails && (
                <ErrorText>{errors.vehicleDetails}</ErrorText>
              )}
            </AnimatedFormSection>

            {/* Lien Section */}
            <ToggleSection>
              <ToggleLabel>
                Do you have a lien(s) on an item or items you’re storing?
              </ToggleLabel>
              <ToggleSubSection>
                <Toggle checked={isLean} onChange={() => setIsLean(!isLean)} />
                <ToggleText>{isLean ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isLean}>
              <Label>Name of Lien Holder *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Name of Lien Holder"
                  value={lienHolderName}
                  onChange={(e) => setLienHolderName(e.target.value)}
                />
                <Icon icon={faUser} />
              </InputWrapper>
              {errors.lienHolderName && (
                <ErrorText>{errors.lienHolderName}</ErrorText>
              )}

              <Label>Value of Lien Items *</Label>
              <InputWrapper>
                <Input
                  type="text" // using text type to display the formatted dollar amount
                  placeholder="Value of Lien Items"
                  value={lienValue}
                  onChange={handleLienValueChange}
                  maxLength="30"
                />
                <Icon icon={faMoneyBill} />
              </InputWrapper>
              {errors.lienValue && (
                <ErrorText>{errors.lienValue}</ErrorText>
              )}

              <Label>List of Items With Lien *</Label>
              <Textarea
                placeholder="List any items with a lien here."
                value={lienItems}
                onChange={(e) => setLienItems(e.target.value)}
              />
              {errors.lienItems && (
                <ErrorText>{errors.lienItems}</ErrorText>
              )}
            </AnimatedFormSection>

            {/* Military Member Section */}
            <ToggleSection>
              <ToggleLabel>
                Are you an active member of the military?
              </ToggleLabel>
              <ToggleSubSection>
                <Toggle
                  checked={isMilitary}
                  onChange={() => setIsMilitary(!isMilitary)}
                />
                <ToggleText>{isMilitary ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isMilitary}>
              <Label>Military Branch *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Military Branch"
                  value={militaryBranch}
                  onChange={(e) => setMilitaryBranch(e.target.value)}
                />
                <Icon icon={faFlag} />
              </InputWrapper>
              {errors.militaryBranch && (
                <ErrorText>{errors.militaryBranch}</ErrorText>
              )}

              <Label>Military Base *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Military Base"
                  value={militaryBase}
                  onChange={(e) => setMilitaryBase(e.target.value)}
                />
                <Icon icon={faFlag} />
              </InputWrapper>
              {errors.militaryBase && (
                <ErrorText>{errors.militaryBase}</ErrorText>
              )}

              <Label>Commanding Officer First Name *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Commanding Officer First Name"
                  value={coFirstName}
                  onChange={(e) => setCoFirstName(e.target.value)}
                />
                <Icon icon={faUser} />
              </InputWrapper>
              {errors.coFirstName && (
                <ErrorText>{errors.coFirstName}</ErrorText>
              )}

              <Label>Commanding Officer Last Name *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Commanding Officer Last Name"
                  value={coLastName}
                  onChange={(e) => setCoLastName(e.target.value)}
                />
                <Icon icon={faUser} />
              </InputWrapper>
              {errors.coLastName && (
                <ErrorText>{errors.coLastName}</ErrorText>
              )}

              <Label>Commanding Officer's Phone Number *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  value={coPhone}
                  onChange={handlePhoneNumberChange}
                  placeholder="Phone Number"
                  maxLength="12"
                />
                <Icon icon={faPhone} />
              </InputWrapper>
              {errors.coPhone && (
                <ErrorText>{errors.coPhone}</ErrorText>
              )}
            </AnimatedFormSection>

            {/* Business Rental Section */}
            <ToggleSection>
              <ToggleLabel>Are you renting under a business name?</ToggleLabel>
              <ToggleSubSection>
                <Toggle
                  checked={isBusiness}
                  onChange={() => setIsBusiness(!isBusiness)}
                />
                <ToggleText>{isBusiness ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isBusiness}>
              <Label>Legal Name of Business *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Legal Name of Business"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
                <Icon icon={faBriefcase} />
              </InputWrapper>
              {errors.businessName && (
                <ErrorText>{errors.businessName}</ErrorText>
              )}

              <Label>How long do you plan to store? *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Select Storage Period"
                  value={storagePeriod}
                  onChange={(e) => setStoragePeriod(e.target.value)}
                />
                <Icon icon={faCalendarAlt} />
              </InputWrapper>
              {errors.storagePeriod && (
                <ErrorText>{errors.storagePeriod}</ErrorText>
              )}
            </AnimatedFormSection>

            <ConfirmButton onClick={handleSubmit}>Submit Details</ConfirmButton>
            <Note>Almost there!</Note>
          </>
        )}
      </ModalContainer>
    </ModalWrapper>
  );
}

export default ExtendedContactModal;
