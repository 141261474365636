import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faMapMarkerAlt,
  faBuilding,
  faCity,
  faFlag,
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import { updateTenant } from "../redux/profileSlice"; // Adjust the import path as needed

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 90%;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Select = styled.select`
  padding: 10px 10px 10px 35px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  appearance: none;
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const MessageContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #555;
`;

const ContactButton = styled.button`
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #e22c2a;
  color: #fff;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #b2221f;
  }
`;

// Array of US states with two-letter codes and full names
const states = [
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PA", name: "Pennsylvania" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
  { code: "PR", name: "Puerto Rico" }
];

function AddressForm({ facility }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const tenant = useSelector((state) => state.auth.profile);
  const storeId = useSelector((state) => state.auth.s);

  // Local state for form fields initialized from the profile
  const [firstName] = useState(profile.first_name || "");
  const [lastName] = useState(profile.last_name || "");
  const [address, setAddress] = useState(profile.address || "");
  const [addressLine2, setAddressLine2] = useState(profile.address_line_2 || "");
  const [city, setCity] = useState(profile.city || "");
  const [stateVal, setStateVal] = useState(profile.state || "");
  const [zip, setZip] = useState(profile.zip_code || "");
  const [email, setEmail] = useState(profile.email || "");
  const [phone, setPhone] = useState(profile.phone || "");
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  // Update local state if profile changes
  useEffect(() => {
    setAddress(profile.address || "");
    setAddressLine2(profile.address_line_2 || "");
    setCity(profile.city || "");
    setStateVal(profile.state || "");
    setZip(profile.zip_code || "");
    setEmail(profile.email || "");
    if (profile.phone) {
      setPhone(formatPhoneNumber(profile.phone));
    } else {
      setPhone("");
    }
  }, [profile]);

  // Since fields are optional, we don't enforce any validation rules
  const validateForm = () => {
    setErrors({});
    return true;
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    if (cleaned.length < 4) return cleaned;
    if (cleaned.length < 7) return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    let tenantId = tenant?.i || null;
    let facilityId = storeId || null;
    // Construct payload from the form fields; first and last name won't be updated
    const payload = {
      address: address,
      city: city,
      state: stateVal, // This will be the 2-letter code
      zip: zip,
      email: email,
      phone: phone,
      changeUser: tenant?.first_name+" "+tenant?.last_name || "unknown user"
    };

    dispatch(updateTenant({ payload, tenantId, facilityId }))
      .unwrap()
      .then((data) => {
        setMessage("Your information has been updated successfully.");
      })
      .catch((error) => {
        setMessage("An error occurred while updating your information.");
        console.error("Update tenant error:", error);
      });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Label>Full Name</Label>
      <InputWrapper>
        <Icon icon={faUser} />
        <Input
          type="text"
          placeholder="Full Name"
          value={`${firstName} ${lastName}`}
          readOnly
          disabled
        />
      </InputWrapper>

      <Label>Address</Label>
      <InputWrapper>
        <Icon icon={faMapMarkerAlt} />
        <Input
          type="text"
          placeholder="Enter your address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          maxLength={100}
        />
      </InputWrapper>
      {errors.address && <ErrorText>{errors.address}</ErrorText>}


      <Label>City</Label>
      <InputWrapper>
        <Icon icon={faCity} />
        <Input
          type="text"
          placeholder="Enter your city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          maxLength={50}
        />
      </InputWrapper>
      {errors.city && <ErrorText>{errors.city}</ErrorText>}

      <Label>State/Province/Region</Label>
      <InputWrapper>
        <Icon icon={faFlag} />
        <Select
          value={stateVal}
          onChange={(e) => setStateVal(e.target.value)}
        >
          <option value="">Select State</option>
          {states.map((s) => (
            <option key={s.code} value={s.code}>
              {s.code} - {s.name}
            </option>
          ))}
        </Select>
      </InputWrapper>
      {errors.state && <ErrorText>{errors.state}</ErrorText>}

      <Label>ZIP/Postal Code</Label>
      <InputWrapper>
        <Icon icon={faEnvelope} />
        <Input
          type="text"
          placeholder="Enter your ZIP/postal code"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          maxLength={12}
        />
      </InputWrapper>
      {errors.zip && <ErrorText>{errors.zip}</ErrorText>}

      <Label>Email</Label>
      <InputWrapper>
        <Icon icon={faEnvelope} />
        <Input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          maxLength={100}
          readOnly
          disabled
        />
      </InputWrapper>
      {errors.email && <ErrorText>{errors.email}</ErrorText>}

      <Label>Phone Number</Label>
      <InputWrapper>
        <Icon icon={faPhone} />
        <Input
          type="text"
          placeholder="Enter your phone number"
          value={phone}
          onChange={handlePhoneNumberChange}
          maxLength={12}
        />
      </InputWrapper>
      {errors.phone && <ErrorText>{errors.phone}</ErrorText>}

      <ContactButton type="submit">Update Profile</ContactButton>
      {message && <MessageContainer>{message}</MessageContainer>}
      <div style={{height:100}}></div>
    </FormContainer>
  );
}

export default AddressForm;
